interface dataPrivacyConsent{
    dataPrivacyType?:string;
    consented?:boolean;
    dateOfConsent?:string;
}

export interface optoutPost{
    dataPrivacyConsent:dataPrivacyConsent[];
}

export const dataPrivacyConsent:dataPrivacyConsent[] = [
    { dataPrivacyType: "RESEARCHMARKETINGANALYSIS"},
    { dataPrivacyType: "COMMERCIALIZATION"},
    { dataPrivacyType: "PROFILINGANDPERSONLAIZATION"}
 ]

export interface optoutGet{
    output: {
        recipientProfile: {
            dataPrivacyConsent:dataPrivacyConsent[];
        },
        recipientProfileEnrollmentStatus?:string,
        recipientServicesFeatureFlag?:string,
        ctype?:string
    }
}