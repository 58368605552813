// gdl.service.js
export const gdlServiceFactory = () => {
    const gdlQueue = window.FDX.GDL;
  
    const sendPageview = (pageId) => {
      gdlQueue.push(['event:publish', ['page', 'pageinfo', { pageId }]]);
    };
  
    const sendEvent = (category, name, payload) => {
      gdlQueue.push(['event:publish', [category, name, payload]]);
    };

    const enableDebugMode=()=>{
      gdlQueue.debug.enable();
    } 
    
    return {
      enableDebugMode,
      sendPageview,
      sendEvent,
    };
  };