import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../global-constants';
import { environment } from 'src/environments/environment';
import { optoutGet } from 'src/app/model/optout.model';
import { optoutPost } from 'src/app/model/optout.model';

@Injectable({
  providedIn: 'root'
})
export class OptoutService {

  apiURL=environment.apifURL;
  getPreferenceApi=GlobalConstants.getPreferenceEndpoint;
  updatePreferenceApi=GlobalConstants.updatePreferenceEndpoint;

  constructor(private httpClient:HttpClient) {}    

  /*fetch the user opted in or opted out details from back-end service */
  deliveryManagerInquiryResponse():Observable<optoutGet>{
    return this.httpClient.post<optoutGet>(`${this.apiURL}${this.getPreferenceApi}`, 
    {
      "recipientProfileProcessDirectiveVO":{"skipRecipientNotificationPreferencesRetrieval":true}
    }, 
    {
      withCredentials: true
    })
  }

  /*posts the user opted details in the back-end service */
  deliveryManagerMaintenanceRequest(data:optoutPost):Observable<optoutPost>{
    return this.httpClient.put<optoutPost>(`${this.apiURL}${this.updatePreferenceApi}`,
    data, 
    {
      withCredentials: true
    })
  }
}
