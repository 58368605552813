import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {AppMediator} from './app.mediator';
import {of,Subject} from 'rxjs';
import {FooterComponent} from '@fedex/caas/lib/module/footer/footer.component';
import {HeaderComponent} from '@fedex/caas/lib/module/header/header.component';

import {UserMediator2Service} from '@fedex/caas';
import { LoaderService } from './services/loader.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
 
@Component({ 
  selector: 'app-root',
  template:`
  <fdx-common-core>
    <fdx-caas-header #fdxCaasHeader
    [enableBanner]="appMediator.toggleBanner$ | async"
    [enableHeader]="appMediator.toggleHeader$ | async"
    [enableLogin]="appMediator.toggleLogin$ | async">
    </fdx-caas-header>

    <main class="caas-s-wrapper ">
      <div class="caas-s-main-content" >
        <router-outlet></router-outlet>
      </div>
    </main>
    <fdx-caas-footer></fdx-caas-footer>
  <fdx-common-core>`,

  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  @ViewChild('fdxCaasHeader')
  headerComponent: HeaderComponent;

  @ViewChild('fdxCaasFooter')
  footerComponent: FooterComponent;

  locale: string;
  public hostURL=environment.hostURL;

  constructor(
    public appMediator: AppMediator,private userMediator: UserMediator2Service,private http:HttpClient) {
    this.appMediator.onAlert$.subscribe(alert => this.headerComponent.alerts$ = of(alert));
    this.appMediator.onNotice$.subscribe(notice => this.footerComponent.notices$ = of(notice));
  }

  ngOnInit(): void {
    //Emits events whenever the user's state changes to anonymized. The event emits null.
    this.userMediator.onAnonymous$.subscribe((user) => {
      if(user===null) {
        window.location.href=`${this.hostURL}/secure-login/en-us/#/login-credentials`;
      }
    });
}

}
