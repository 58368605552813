import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { OptoutService } from 'src/app/services/optout.service';
import { environment } from 'src/environments/environment';
import { API_CONFIG_TOKEN } from 'src/app/token';
import { ApiConfig } from '../../model/apiConfig';
import staticContentJson from 'src/assets/staticContent.json';
import { gdlServiceFactory } from '../../services/gdl.service';
import { GlobalConstants } from '../../global-constants';
import { dataPrivacyConsent} from 'src/app/model/optout.model';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-opt-out-page',
  templateUrl: './opt-out-page.component.html',
  styleUrls: ['./opt-out-page.component.scss']
})
export class OptOutPageComponent implements OnInit {

  //initialize gdl Service
  gdlService = gdlServiceFactory();

  //get pageid from globalConstants.ts
  pageId = GlobalConstants.gdlOptOutPageId;

  //create instance of FormGroup stored in optOutForm
  optOutForm: FormGroup;

  //conditionally show/hide FDM User screen
  showForm: boolean;

  //conditionally show/hide serverError screen.
  serverError: boolean;

  //conditionally show/hide FDM User screen
  showNonFDMForm: boolean;

  //get staticdata in html from json file
  staticData: any;

  //object to send payload to gdl Event
  gdlObj: any = {};
  
  //get the current state of loading spinner
  isLoading = this.loaderservice.isLoading;

  //object to store gdl data
  gdlData: any = {};

  //store previous value of optOutConsents in gdl object
  optOutConsentsPrevious: any;

  //Store the response from getPreference
  apiResponse:any;

  //get the current hostURl 
  public hostURL = environment.hostURL;

  //conditionally show/hide Opt In For All and Opt Out For All
  displayOptInOption: boolean = false;

  //store current value of date_flag
  fdm_opt_flag:boolean=true;

  //get the form values
  optoutFormPayload:any={};

  constructor(@Inject(API_CONFIG_TOKEN) private readonly apiConfig:ApiConfig,private loaderservice: LoaderService, private fb: FormBuilder, private router: Router, private cookieService: CookieService, private optOutService: OptoutService, public loaderService: LoaderService) {}

  //if the back-end returns an status code 403 displays Non-FDM USER error screen, else display server error screen.
  handleStatusCodeError(error) {
    this.showForm = false;
    if (error.error && error.error.statusCode === 403) {
      this.serverError = false;
      this.showNonFDMForm = true;
    }
    else if(error.error && error.error.statusCode === 500){
      this.serverError = true;
      this.showNonFDMForm = false;
    }
    else {
      //console.log("tried to redirect")
      window.location.href=`${this.hostURL}/en-us/delivery-manager/privacy.html`;
    }
  }

  ngOnInit(): void {
    this.optOutForm = this.fb.group({
      privacy_opt_out_flag:[false],
      sell_opt_out_flag: [false],
      target_opt_out_flag: [false]
    });

    this.showForm = false;
    this.serverError = false;
    this.showNonFDMForm = false;

    //fetch static HTML content from json file
    this.staticData = staticContentJson;

    //set gdl Form event submit preference button 
    this.gdlObj.name = 'fdmpnoptout-preference';

    this.getPreference();
  }

  //changes the label Opt In For All if all checkbox values are true and Opt Out For All if any one value is false.
  changeSelection() {
    if (
      this.optOutForm.value.sell_opt_out_flag == true &&
      this.optOutForm.value.target_opt_out_flag == true) {
      this.displayOptInOption = true;
    }
    else {
      this.displayOptInOption = false;
    }
  }

  //make all the checkbox values as true(opt-out) or false(opt-in)
  optForAll(checked: boolean) {
    this.displayOptInOption = (checked === true) ? true : false;
    this.optOutForm.controls['sell_opt_out_flag'].setValue(checked)
    this.optOutForm.controls['target_opt_out_flag'].setValue(checked)
  }

  //get the consent value of particular field
  getConsentValue(dataPrivacyType:string){
    let dataPrivacyConsent=this.apiResponse;
    for (let index of dataPrivacyConsent){
      if(index.dataPrivacyType === dataPrivacyType){
        return index.consented;
      }
    }
  }

  //call DeliveryManagerInquiryResponse from back-end service to bind the checkbox as selected
  getPreference() {
    this.optOutService.deliveryManagerInquiryResponse().subscribe(res => {
      this.apiResponse=res.output?.recipientProfile?.dataPrivacyConsent;
      //call GDL pageview event
      this.gdlService.sendPageview(this.pageId);

      this.showForm = true;
      this.serverError = false;
      this.showNonFDMForm = false;
      //checkmark fun, values are inversed
      this.optOutForm.controls['sell_opt_out_flag'].setValue(this.getConsentValue('COMMERCIALIZATION'))
      this.optOutForm.controls['target_opt_out_flag'].setValue(this.getConsentValue('PROFILINGANDPERSONLAIZATION'))
      this.optOutForm.controls['privacy_opt_out_flag'].setValue(this.getConsentValue('RESEARCHMARKETINGANALYSIS'))
      //this.optOutForm.patchValue({
      //  privacy_opt_out_flag: [this.getConsentValue('RESEARCHMARKETINGANALYSIS')],
      //  sell_opt_out_flag: [this.getConsentValue('COMMERCIALIZATION')],
      //  target_opt_out_flag: [this.getConsentValue('PROFILINGANDPERSONLAIZATION')]
      //})

      //check for consent values, if all true(opt-out) display opt-in-for-all label
      //if (this.getConsentValue('COMMERCIALIZATION') === true
      //  && this.getConsentValue('PROFILINGANDPERSONLAIZATION') === true)
      //  this.displayOptInOption = true;
      this.changeSelection()
      //set previous value of optOutConsents for gdl payload
      //if(this.fdm_opt_flag===true){
        this.optOutConsentsPrevious = {
          sell_opt_out_flag: this.getConsentValue('COMMERCIALIZATION'),
          target_opt_out_flag: this.getConsentValue('PROFILINGANDPERSONLAIZATION')
        }
      //}
      //else {
      //  this.optOutConsentsPrevious = {
      //    privacy_opt_out_flag: this.getConsentValue('RESEARCHMARKETINGANALYSIS'),
      //  }
      //}
    }, ((error: HttpErrorResponse) => {
      this.handleStatusCodeError(error);
    }));
  }

  //add dataPrivacy Type and consented values in the optout form payload based on fdm opt flag
  setDataPrivacyConsentValues(dataPrivacyConsent:any){ 
    let dataPrivacyArr=[];
    console.log(dataPrivacyConsent)
    dataPrivacyConsent.forEach((element)=>{
      if(element.dataPrivacyType === "RESEARCHMARKETINGANALYSIS"){
        element.consented=this.optOutForm.controls.privacy_opt_out_flag.value;
        dataPrivacyArr.push(element);
      }
      else if(element.dataPrivacyType === "COMMERCIALIZATION"){
        element.consented=this.optOutForm.controls.sell_opt_out_flag.value;
        dataPrivacyArr.push(element);
      }
      else if(element.dataPrivacyType === "PROFILINGANDPERSONLAIZATION"){
        element.consented=this.optOutForm.controls.target_opt_out_flag.value;
        
        dataPrivacyArr.push(element);
      }
    })
    return dataPrivacyArr;
  }

  //posts users appropriate response from the optOut form to the back-end service DeliveryManagerMaintenanceRequest
  submitOptFormValue() {
    //set the current and previous value of optOutConsents in gdlObj data
    //if(this.fdm_opt_flag===true){
      this.gdlData.optOutConsentsCurrent = {
        sell_opt_out_flag: this.optOutForm.get('sell_opt_out_flag').value,
        target_opt_out_flag: this.optOutForm.get('target_opt_out_flag').value,
      }
    //}
    //else{
      //just forwarded from
      this.gdlData.optOutConsentsCurrent = {
        privacy_opt_out_flag: this.optOutForm.get('privacy_opt_out_flag').value,
      }
    //}

    this.gdlData.optOutConsentsPrevious = this.optOutConsentsPrevious;
    this.gdlObj.data = this.gdlData;

    //set the opt out values to pass in payload to updatePreference
    this.optoutFormPayload.dataPrivacyConsent=this.setDataPrivacyConsentValues(dataPrivacyConsent);

    this.optOutService.deliveryManagerMaintenanceRequest(this.optoutFormPayload).subscribe((res) => {
      //event will be published when the form is successfully submitted.At this point form data will be collected to AA.
      this.gdlService.sendEvent('form', 'submit', this.gdlObj);
      this.router.navigate(['/en-us/delivery-manager/privacy/opt-out-confirm']);
    }, ((error: HttpErrorResponse) => {
      this.handleStatusCodeError(error)
    }))

  }
}


