<fdx-common-core>
    <app-loading-spinner *ngIf="(isLoading | async) ; else Loaded"></app-loading-spinner>
    <ng-template #Loaded>
        <div class="contentLoaded">

            <div class="main-content-section fdx-u-pb--10 fdx-u-pt--10" *ngIf="showNonFDMForm">
                <p class="fdx-c-paragraph fdx-u-fontsize--h5 fdx-u-mb--5">{{staticData?.nonFdmErrorPage?.title[0]}}
                    <a href="{{hostURL}}/en-us/trust-center/privacy.html" class="hyperlink-style-inherit fdx-c-link"
                        target="_blank">
                        {{staticData?.nonFdmErrorPage?.title[1]}}
                    </a>
                </p>

                <p class="fdx-c-paragraph fdx-u-fontsize--h5">{{staticData?.nonFdmErrorPage?.subheading[0]}}
                    <a href="{{hostURL}}/fdmenrollment/?signup=true" class="hyperlink-style-inherit fdx-c-link"
                        target="_blank">
                        {{staticData?.nonFdmErrorPage?.subheading[1]}}
                    </a>
                </p>
            </div>


            <div class="title-section fdx-u-display--flex fdx-u-flex-align-items--center fdx-u-flex-justify-content--center fdx-u-pb--15 fdx-u-pt--15"
                *ngIf="serverError">
                <div class="error-section">
                    <img src="assets/svg/Purple Alert Icon - Large.svg" class="error-icon">
                </div>
                <div class="error-content-section">
                    <p class="fdx-c-paragraph fdx-u-fontsize--h5">{{staticData.serverErrorPage?.title}}</p>
                </div>
            </div>


            <div class="main-content-section fdx-u-pb--10 fdx-u-pt--8" *ngIf="showForm">

                <div class="heading-content">
                    <h1
                        class="fdx-c-heading  fdx-c-heading--h2 fdx-u-fontweight--medium fdx-u-mb--4 fdx-u-color--grey-40 grey-40">
                        {{staticData?.optOutPage?.title}}</h1>
                    <p class="fdx-c-heading  fdx-c-heading--h4 fdx-u-fontweight--medium">
                        {{staticData?.optOutPage?.subheading[0]}}
                        <a class="hyperlink-style-inherit fdx-c-link"
                            href="{{hostURL}}/en-us/delivery-manager/privacy.html" target="_blank">
                            {{staticData?.optOutPage?.subheading[1]}}
                        </a>
                        {{staticData?.optOutPage?.subheading[2]}}
                    </p><br />
                </div>

                <div class="form-content">
                    <form [formGroup]="optOutForm" (ngSubmit)="submitOptFormValue()">
                        
                        <div class="form-group">
                            <ng-container *ngIf="fdm_opt_flag">
                                <div
                                    class="optout-section fdx-u-display--flex fdx-u-flex-align-items--center fdx-u-flex-justify-content--center fdx-u-flex-direction--column fdx-u-pb--6">
                                    <p class="opt-option-section fdx-c-heading fdx-c-heading--h4 fdx-u-fontweight--medium"
                                        (click)="optForAll(true)" id="optOut" [hidden]="displayOptInOption">{{staticData.optOutPage?.buttonsContent[5]}}</p>
                                    <p class="opt-option-section fdx-c-heading  fdx-c-heading--h4 fdx-u-fontweight--medium"
                                        *ngIf="displayOptInOption" id="optOut" (click)="optForAll(false)">{{staticData.optOutPage?.buttonsContent[4]}}</p>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!fdm_opt_flag; else fdm_sell_target">
                                <div class="checkbox-section  fdx-u-pt--6">
                                    <div class="toggle-container">
                                        <input type="checkbox" id="privacy" class="slider-toggle"
                                            [ngModel]="!optOutForm.get('privacy_opt_out_flag').value"
                                            (ngModelChange)="optOutForm.get('privacy_opt_out_flag').setValue(!$event)"
                                            [ngModelOptions]="{standalone:true}">
                                        <label for="privacy"></label>
                                        <div class="toggle-text" data-toggle-true="Yes" data-toggle-false="No"></div>
                                    </div>
                                    <div class="checkbox-content">
                                        <p class="fdx-c-heading  fdx-c-heading--h5 fdx-u-fontweight--medium">
                                            {{staticData.optOutPage?.privacyOptContent}}
                                        </p>
                                    </div>
                                </div><br>
                            </ng-container>
                            
                            <ng-template #fdm_sell_target>
                                <div class="checkbox-section">
                                    <div class="toggle-container">
                                        <input type="checkbox" id="datasell" class="slider-toggle"
                                            [ngModel]="!optOutForm.get('sell_opt_out_flag').value"
                                            (ngModelChange)="optOutForm.get('sell_opt_out_flag').setValue(!$event)"
                                            (change)="changeSelection()" [ngModelOptions]="{standalone:true}">
                                        <label for="datasell"></label>
                                        <div class="toggle-text" data-toggle-true="Yes" data-toggle-false="No"></div>
                                    </div>
                                    <div class="checkbox-content">
                                        <p class="fdx-c-heading  fdx-c-heading--h5 fdx-u-fontweight--medium">
                                            {{staticData.optOutPage?.sellOptContent}}
                                        </p>
                                    </div>
                                </div><br>

                                <div class="checkbox-section">
                                    <div class="toggle-container">
                                        <input type="checkbox" id="datatarget" class="slider-toggle"
                                            [ngModel]="!optOutForm.get('target_opt_out_flag').value"
                                            (ngModelChange)="optOutForm.get('target_opt_out_flag').setValue(!$event)"
                                            (change)="changeSelection()" [ngModelOptions]="{standalone:true}">
                                        <label for="datatarget"></label>
                                        <div class="toggle-text" data-toggle-true="Yes" data-toggle-false="No"></div>
                                    </div>
                                    <div class="checkbox-content">
                                        <p class="fdx-c-heading  fdx-c-heading--h5 fdx-u-fontweight--medium">
                                            {{staticData.optOutPage?.targetOptContent}}
                                        </p>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                        <p class="fdx-c-paragraph fdx-u-fontsize--h5 fdx-u-pt--6">
                            {{staticData?.optOutPage?.choiceContent[0]}}
                            <a href="{{hostURL}}/en-us/terms-of-use.html" class="hyperlink-style-inherit fdx-c-link"
                                target="_blank">
                                {{staticData?.optOutPage?.choiceContent[1]}}
                            </a>
                        </p>

                        <div
                            class="fdx-u-display--flex fdx-u-flex-align-items--center fdx-u-flex-justify-content--center fdx-u-flex-direction--column fdx-u-mt--15">
                            <div class="preference-button-content">
                                <button class="fdx-c-button fdx-c-button--primary ">
                                    {{staticData.optOutPage?.buttonsContent[2]}}
                                </button>
                            </div>
                            <div class="back-button-content fdx-u-pt--5">
                                <a href="{{hostURL}}/en-us/delivery-manager/privacy.html"
                                    class="fdx-c-button fdx-c-button--primary-outline back-button-styling">
                                    {{staticData.optOutPage?.buttonsContent[1]}}
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-template>
    
</fdx-common-core>