import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { OptoutService } from 'src/app/services/optout.service';
import { environment } from 'src/environments/environment';
import { API_CONFIG_TOKEN } from 'src/app/token';
import { ApiConfig } from '../../model/apiConfig';
import { DatePipe } from '@angular/common';
import staticContentJson from 'src/assets/staticContent.json';
import { gdlServiceFactory } from '../../services/gdl.service';
import { GlobalConstants } from '../../global-constants';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-opt-confirm-screen',
  templateUrl: './opt-confirm-screen.component.html',
  styleUrls: ['./opt-confirm-screen.component.scss']
})
export class OptConfirmScreenComponent implements OnInit {

  //initialize gdl Service
  gdlService = gdlServiceFactory();

  //get pageid from globalConstants.ts
  pageId=GlobalConstants.gdlOptConfirmPageId;

  //conditionally show/hide serverError screen
  serverError:boolean;

  //conditionally show/hide FDM User screen
  showForm:boolean;

  //conditionally show/hide FDM User screen
  showNonFDMForm:boolean;

  //get user name 
  userName:string;

  //get static data from json file
  staticData:any;
  
  //get the current state of loading spinner
  isLoading=this.loaderservice.isLoading;

  //set the opt-in and opt-out message data sell
  sellContent:boolean=false;

  //set the opt-in and opt-out message data sell
  privacyContent:boolean=false;

  //set the opt-in and opt-out message data target
  targetContent:boolean=false;

  //set the dateOfConsent
  dateOfConsent:any;

  //store current value of date_flag
  fdm_opt_flag:boolean=true;

//Store the response from getPreference
  apiResponse:any;

  //if the back-end returns an status code 403 displays Non-FDM USER error screen, else display server error screen.
  handleStatusCodeError(error){
    this.showForm=false;
    
    if (error.error && error.error.statusCode === 403) {
      this.serverError = false;
      this.showNonFDMForm = true;
    }
    else if(error.error && error.error.statusCode === 500){
      this.serverError = true;
      this.showNonFDMForm = false;
    }
    else {
      window.location.href=`${this.hostURL}/en-us/delivery-manager/privacy.html`;
    }
  }

  public hostURL=environment.hostURL;

  constructor(@Inject(API_CONFIG_TOKEN) private readonly apiConfig:ApiConfig,private loaderservice:LoaderService,public cookieService: CookieService,public optOutService:OptoutService, public datePipe:DatePipe) {}

  ngOnInit(){
    this.showForm=false;   
    this.serverError=false; 
    this.showNonFDMForm=false;

    //set static content from JSON in object staticData
    this.staticData=staticContentJson;

    this.getPreference();
  }

  //get the consent value of particular field
  getConsentValue(dataPrivacyType:string){
    let dataPrivacyConsent=this.apiResponse;
    for (let index of dataPrivacyConsent){
      if(index.dataPrivacyType === dataPrivacyType){
        return index.consented;
      }
    }
  }

  //get the consent date of particular field
  getConsentDate(dataPrivacyType:string){
    let dataPrivacyConsent=this.apiResponse;
    for (let index of dataPrivacyConsent){
      if(index.dataPrivacyType === dataPrivacyType){
        return index.dateOfConsent;
      }
    }
  }

   //call DeliveryManagerInquiryResponse service from back-end service and show appropriate screen
  getPreference(){
    this.optOutService.deliveryManagerInquiryResponse().subscribe((res)=>{
      this.apiResponse=res.output.recipientProfile.dataPrivacyConsent;
      //get user name from the cookie and remove any double quotes from the string
      this.userName=this.cookieService.get('fcl_contactname').replace(/"/g, '');

      //call GDL pageview event
      this.gdlService.sendPageview(this.pageId);

      //get the privacy consent value
      this.privacyContent=this.getConsentValue('RESEARCHMARKETINGANALYSIS'),
      //get the sell consent value 
      this.sellContent=this.getConsentValue('COMMERCIALIZATION'),
      //get the target consent value
      this.targetContent=this.getConsentValue('PROFILINGANDPERSONLAIZATION') //TYPO FROM APIF

      this.dateOfConsent=this.getConsentDate('COMMERCIALIZATION')

      this.showForm=true;   
      this.serverError=false; 
      this.showNonFDMForm=false;

    },((error: HttpErrorResponse) => {
        this.handleStatusCodeError(error)
      })
    );
  }

}
