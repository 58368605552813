import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }
  public loading=new BehaviorSubject<boolean>(false);
  public isLoading = this.loading.asObservable();
  //show the loading spinner
  show(){
      this.loading.next(true);
  }
  //hide the loading spinnder
  hide(){
      this.loading.next(false);
  }
}

