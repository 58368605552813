import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Alert} from '@fedex/caas/lib/domain/Alert';

@Injectable({
  providedIn: 'root'
})
export class AppMediator {
  toggleBanner$ = new BehaviorSubject<boolean>(true);
  toggleLogin$ = new BehaviorSubject<boolean>(true);
  toggleHeader$ = new BehaviorSubject<boolean>(true);
  toggleFooter$ = new BehaviorSubject<boolean>(true);
  toggleCallToAction$ = new BehaviorSubject<boolean>(true);
  onAlert$ = new Subject<Alert[]>();
  onNotice$ = new Subject<Alert[]>();

  toggleBanner(on?: boolean): void {
    this.toggleBanner$.next(!!on || !this.toggleBanner$.value);
  }

  toggleLogin(on?: boolean): void {
    this.toggleLogin$.next(!!on || !this.toggleLogin$.value);
  }

  toggleHeader(on?: boolean): void {
    this.toggleHeader$.next(!!on || !this.toggleHeader$.value);
  }

  toggleFooter(on?: boolean): void {
    this.toggleFooter$.next(!!on || !this.toggleFooter$.value);
  }

  nextAlert(alert: Alert[]): void {
    this.onAlert$.next(alert);
  }

  nextNotice(notice: Alert[]): void {
    this.onNotice$.next(notice);
  }

  toggleCallToAction(on?: boolean): void {
    this.toggleCallToAction$.next(!!on || !this.toggleCallToAction$.value);
  }
}
