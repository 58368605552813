import { NgModule } from '@angular/core';
import { RouterModule, Routes,CanActivate } from '@angular/router';
import { OptConfirmScreenComponent } from './opt-out/opt-confirm-screen/opt-confirm-screen.component';
import { OptOutPageComponent } from './opt-out/opt-out-page/opt-out-page.component';

const routes: Routes = [
  {path:"en-us/delivery-manager/privacy/opt-out",component:OptOutPageComponent},
  {path:"en-us/delivery-manager/privacy/opt-out-confirm",component:OptConfirmScreenComponent},
  {path:'', redirectTo: "/en-us/delivery-manager/privacy/opt-out", pathMatch: 'full' } 
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
