import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FedexCaasModule} from '@fedex/caas';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {CAAS_CONFIGURATOR, CaasConfig} from '@fedex/caas';

import { OptOutPageComponent } from './opt-out/opt-out-page/opt-out-page.component';
import { OptConfirmScreenComponent } from './opt-out/opt-confirm-screen/opt-confirm-screen.component';
import { CookieService } from 'ngx-cookie-service';
import { CommonCoreModule } from '@fedex/common-core';
import { LoaderService } from './services/loader.service';
import { OptoutService } from './services/optout.service';
import { GlobalHttpInterceptorService } from './services/global-http-interceptor.service';
import { DatePipe } from '@angular/common';
import { ApiConfig } from '../app/model/apiConfig';
import { API_CONFIG_TOKEN } from './token';
import { environment } from 'src/environments/environment';
import { LoadingSpinnerComponent } from './opt-out/loading-spinner/loading-spinner.component';

const host=environment.host;
const apigURL=environment.apigURL;

@NgModule({
  declarations: [
    AppComponent,
    OptOutPageComponent,
    OptConfirmScreenComponent,
    LoadingSpinnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FedexCaasModule,
    ReactiveFormsModule,
    FedexCaasModule,
    CommonCoreModule,
    HttpClientModule,

  ],
  providers: [
    {
      provide: CAAS_CONFIGURATOR,
      useValue: {
        host: host,
        loglevel: 'OFF',
        adobelaunch: false,
        commoncore:false,
        apigURL: apigURL
      } as unknown as CaasConfig
    },
    {
      provide:API_CONFIG_TOKEN, useValue:{
        useApifApi:true,
      } as unknown as ApiConfig
    },
    CookieService,
    DatePipe,
    LoaderService,
    OptoutService,
    {provide:HTTP_INTERCEPTORS,useClass:GlobalHttpInterceptorService,multi:true}
  ],
  bootstrap: [AppComponent]
},
)
export class AppModule { }
