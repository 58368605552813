<fdx-common-core>
    <app-loading-spinner *ngIf="(isLoading | async) ; else Loaded"></app-loading-spinner>
    <ng-template #Loaded>
        <div class="contentLoaded">
            <div *ngIf="showForm">

                <div class="main-section-content fdx-u-pt--10 fdx-u-pb--10">
                    <h1 class="fdx-c-heading  fdx-c-heading--h2 fdx-u-fontweight--medium fdx-u-color--grey-40 grey-40">
                        {{staticData?.optOutPage?.title}}</h1>

                    <div class="paragraph-content fdx-u-pt--6">
                        <p class="fdx-c-heading fdx-c-heading--h4 fdx-u-fontweight--medium fdx-u-pb--2">
                            {{staticData?.optConfirmPage?.subheading[0]}} {{userName}}'s
                            {{staticData?.optConfirmPage?.subheading[1]}} {{dateOfConsent | date:'longDate'}}.</p>
                        <ul>
                            <ng-container *ngIf="!fdm_opt_flag; else fdm_sell_target">
                                <li *ngIf="privacyContent===false">
                                    <p
                                        class="fdx-c-heading fdx-u-pb--2 fdx-u-pt--4 fdx-c-heading--h5 fdx-u-fontweight--medium">
                                        {{staticData?.optConfirmPage?.privacyOptInContent}}</p>
                                </li>
                                <li *ngIf="privacyContent===true">
                                    <p
                                        class="fdx-c-heading fdx-u-pb--2 fdx-u-pt--4 fdx-c-heading--h5 fdx-u-fontweight--medium">
                                        {{staticData?.optConfirmPage?.privacyOptOutContent}}</p>
                                </li>
                            </ng-container>
                            
                            <ng-template #fdm_sell_target>
                                <li *ngIf="sellContent===false">
                                    <p class="fdx-c-heading fdx-u-pb--2 fdx-c-heading--h5 fdx-u-fontweight--medium">
                                        {{staticData?.optConfirmPage?.sellOptInContent}}</p>
                                </li>
                                <li *ngIf="sellContent===true">
                                    <p class="fdx-c-heading fdx-u-pb--2 fdx-c-heading--h5 fdx-u-fontweight--medium">
                                        {{staticData?.optConfirmPage?.sellOptOutContent}}</p>
                                </li>
                                <li *ngIf="targetContent===false">
                                    <p class="fdx-c-heading  fdx-u-pb--2 fdx-c-heading--h5 fdx-u-fontweight--medium">
                                        {{staticData?.optConfirmPage?.targetOptInContent}}</p>
                                </li>
                                <li *ngIf="targetContent===true">
                                    <p class="fdx-c-heading fdx-u-pb--2 fdx-c-heading--h5 fdx-u-fontweight--medium">
                                        {{staticData?.optConfirmPage?.targetOptOutContent}}</p>
                                </li>
                            </ng-template>
                        </ul>
                    </div>

                    <div
                        class="fdx-u-display--flex fdx-u-flex-align-items--center fdx-u-flex-justify-content--center fdx-u-flex-direction--column fdx-u-mt--7">
                        <div class="ok-button-section">
                            <a href="{{hostURL}}/en-us/delivery-manager/privacy.html"
                                class="fdx-c-button fdx-c-button--primary ok-button-styling">
                                {{staticData.optOutPage?.buttonsContent[3]}}
                            </a>
                        </div>
                        <div class="back-button-section fdx-u-pt--5">
                            <a routerLink="/en-us/delivery-manager/privacy/opt-out"
                                class="fdx-c-button fdx-c-button--primary-outline back-button-styling">
                                {{staticData.optOutPage?.buttonsContent[1]}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="main-content-section fdx-u-pb--10 fdx-u-pt--10" *ngIf="showNonFDMForm">
                <p class="fdx-c-paragraph fdx-u-fontsize--h5 fdx-u-mb--5">{{staticData?.nonFdmErrorPage?.title[0]}}
                    <a href="{{hostURL}}/en-us/trust-center/privacy.html" class="hyperlink-style-inherit fdx-c-link"
                        target="_blank">
                        {{staticData?.nonFdmErrorPage?.title[1]}}
                    </a>
                </p>

                <p class="fdx-c-paragraph fdx-u-fontsize--h5">{{staticData?.nonFdmErrorPage?.subheading[0]}}
                    <a href="{{hostURL}}/fdmenrollment/?signup=true" class="hyperlink-style-inherit fdx-c-link"
                        target="_blank">
                        {{staticData?.nonFdmErrorPage?.subheading[1]}}
                    </a>
                </p>
            </div>


            <div class="title-section fdx-u-display--flex fdx-u-flex-align-items--center fdx-u-flex-justify-content--center fdx-u-pb--15 fdx-u-pt--15"
                *ngIf="serverError">
                <div class="error-section">
                    <img src="assets/svg/Purple Alert Icon - Large.svg" class="error-icon">
                </div>
                <div class="error-content-section">
                    <p class="fdx-c-paragraph fdx-u-fontsize--h5">{{staticData.serverErrorPage?.title}}</p>
                </div>
            </div>
        </div>
    </ng-template>

</fdx-common-core>